exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-blogs-tsx": () => import("./../../../src/pages/account/blogs.tsx" /* webpackChunkName: "component---src-pages-account-blogs-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-preferences-tsx": () => import("./../../../src/pages/account/preferences.tsx" /* webpackChunkName: "component---src-pages-account-preferences-tsx" */),
  "component---src-pages-account-products-tsx": () => import("./../../../src/pages/account/products.tsx" /* webpackChunkName: "component---src-pages-account-products-tsx" */),
  "component---src-pages-print-tsx": () => import("./../../../src/pages/print.tsx" /* webpackChunkName: "component---src-pages-print-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-inner-template-tsx": () => import("./../../../src/templates/blogInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-inner-template-tsx" */),
  "component---src-templates-blog-landing-template-tsx": () => import("./../../../src/templates/blogLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-landing-template-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-bundle-landing-tsx": () => import("./../../../src/templates/bundleLanding.tsx" /* webpackChunkName: "component---src-templates-bundle-landing-tsx" */),
  "component---src-templates-career-page-template-tsx": () => import("./../../../src/templates/careerPageTemplate.tsx" /* webpackChunkName: "component---src-templates-career-page-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/categoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-collection-template-tsx": () => import("./../../../src/templates/collectionTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-template-tsx" */),
  "component---src-templates-contact-page-template-tsx": () => import("./../../../src/templates/contactPageTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-page-template-tsx" */),
  "component---src-templates-corporate-gifting-template-tsx": () => import("./../../../src/templates/corporateGiftingTemplate.tsx" /* webpackChunkName: "component---src-templates-corporate-gifting-template-tsx" */),
  "component---src-templates-faq-template-tsx": () => import("./../../../src/templates/faqTemplate.tsx" /* webpackChunkName: "component---src-templates-faq-template-tsx" */),
  "component---src-templates-feedback-page-template-tsx": () => import("./../../../src/templates/feedbackPageTemplate.tsx" /* webpackChunkName: "component---src-templates-feedback-page-template-tsx" */),
  "component---src-templates-gifting-template-tsx": () => import("./../../../src/templates/giftingTemplate.tsx" /* webpackChunkName: "component---src-templates-gifting-template-tsx" */),
  "component---src-templates-homepage-template-tsx": () => import("./../../../src/templates/homepageTemplate.tsx" /* webpackChunkName: "component---src-templates-homepage-template-tsx" */),
  "component---src-templates-online-order-page-template-tsx": () => import("./../../../src/templates/onlineOrderPageTemplate.tsx" /* webpackChunkName: "component---src-templates-online-order-page-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-recipe-inner-template-tsx": () => import("./../../../src/templates/recipeInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-recipe-inner-template-tsx" */),
  "component---src-templates-recipe-landing-template-tsx": () => import("./../../../src/templates/recipeLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-recipe-landing-template-tsx" */),
  "component---src-templates-recipes-all-tsx": () => import("./../../../src/templates/recipes-all.tsx" /* webpackChunkName: "component---src-templates-recipes-all-tsx" */),
  "component---src-templates-recipes-tsx": () => import("./../../../src/templates/recipes.tsx" /* webpackChunkName: "component---src-templates-recipes-tsx" */),
  "component---src-templates-shipping-policy-page-template-tsx": () => import("./../../../src/templates/shippingPolicyPageTemplate.tsx" /* webpackChunkName: "component---src-templates-shipping-policy-page-template-tsx" */),
  "component---src-templates-shop-all-tsx": () => import("./../../../src/templates/shop-all.tsx" /* webpackChunkName: "component---src-templates-shop-all-tsx" */),
  "component---src-templates-sponsorship-template-tsx": () => import("./../../../src/templates/sponsorshipTemplate.tsx" /* webpackChunkName: "component---src-templates-sponsorship-template-tsx" */),
  "component---src-templates-terms-template-tsx": () => import("./../../../src/templates/termsTemplate.tsx" /* webpackChunkName: "component---src-templates-terms-template-tsx" */)
}

